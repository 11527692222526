*{
    width: 100%;
    margin: 0;
    padding: 0;
}

.payPage{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.payProduct{
    width: 50%;
    align-self: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin:10px;
}

.payProductText{
    display: flex;
    justify-content: space-between  ;
    font-weight: bold;
}

.payProductText>*{
    text-align: center;
    width: auto;
    margin: 0 20px;
}

.payProduct > img{
    height:300px;
    border-radius: 10px;
    object-fit: cover;
}

.pay{
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
}

.pay>*{
    align-self: center;
}

.pay> input{
    margin: 5px 0;
    height: 30px;
    border-radius: 10px;
    border: 1px solid gray;
    padding: 5px;
}

.payButton{
    width: 50%;
    font-size: large;
    font-weight: bold;
    border-radius: 10px;
    margin-top: 20px;
    padding: 10px;
    background-color: #BD0550;
    border: 0;
    color: white;
}

@media all and (max-width: 750px) {

    .payProduct{
        width: 70%;
       
    }

    .payProduct > img{
        height:200px;
        border-radius: 10px;
        object-fit: cover;
    }
    
}