.desuscribe{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.desuscribe> input{
    width: 40%;
    margin: 5px 0;
    height: 30px;
    border-radius: 10px;
    border: 1px solid gray;
    padding: 5px;
}

.desuscribe > button{
    width: 20%;
    font-size: large;
    font-weight: bold;
    border-radius: 10px;
    margin-top: 20px;
    padding: 10px;
    background-color: #BD0550;
    border: 0;
    color: white;
}

.desuscribeMessage{
    margin-top: 50px;
    text-align: center;
    color: red  ;
    font-size: large;
}

.desuscribeModal{
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: x-large;
    font-weight: bold;
    background-color: white;
}

.desuscribeModalDiv{
    display: flex;
    justify-content: space-around;
}

.desuscribeModalDiv > button{
    width: 20%;
    font-size: large;
    font-weight: bold;
    border-radius: 10px;
    margin-top: 20px;
    padding: 10px;
    background-color: #BD0550;
    border: 0;
    color: white;
}

@media all and (max-width: 750px) {
    .desuscribe> input{
        width: 60%;
        margin: 5px 0;
        height: 30px;
        border-radius: 10px;
        border: 1px solid gray;
        padding: 5px;
    }

    .desuscribe > button{
        width: 50%;
    }
    
}