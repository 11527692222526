*{
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
}

.productName{
    display: flex;
    flex-direction: column;
}

.desubscribe{
    width: auto;
    align-self: flex-end;
    text-align: end;
    margin-top: 20px;
    margin-right: 20px;
}

.products{
    margin: auto;
    margin-top: 50px;
    display: flex;
    justify-content: space-evenly;
}

.productCard{
    padding: 20px;
    width: 30%;
    box-shadow: 2px 2px 8px #888888;
    border-radius: 20px ; 
    align-self: center;
}

.productImage{
    border-radius: 20px;
}

.productName{
    font-size: large;
    font-weight: bold;
    margin: 10px;
}

.productDescription{
    margin: 10px;

}

.productPrice{
    font-size: large;   
    font-weight: bold;
    margin: 10px;

}

.productButton{
    font-size: large;
    font-weight: bold;
    border-radius: 10px;
    padding: 10px;
    background-color: #BD0550;
    border: 0;
    color: white;
}

@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #383636; /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }

  .spinner-container{
    display: flex;
    justify-content:center;
  }


@media all and (max-width: 750px) {
    .products{
        margin:20px;
        flex-direction: column;
        gap:20px
    }
    
    .productCard{
        width: 70%;
    }
}